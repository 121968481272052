.item {
    padding: 8px 0;
}

.card {
     background-color: #c8eeff; 
}

.stockCard {
    background-color: #a8e1fc; 
}

/* this class is used to show antd range picker dialog */
.ant-picker-dropdown {
    z-index: 10000 !important;
}
